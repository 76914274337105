import React, { useState, useEffect } from 'react';
import ChatInterface from './components/ChatInterface';

function App() {

  return (
    <div className="App">
      <ChatInterface />
    </div>
  );
}

export default App;